import React from 'react';
import ReactGA from "react-ga4";
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById("m2z49"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

ReactGA.initialize("G-SJS8M2P9M7");