import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';

import Changelog from './components/Changelog';
import Colors from './components/Colors';
import Category from './components/Category';

function App() {
  return (
    <>
      <Router>
        <Routes>
		<Route exact path="/" element={<Changelog />} />
		<Route exact path="/colors" element={<Colors />} />
		<Route exact path="/category/:id" element={<Category />} />
          {/* <Navigate to="/" /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
